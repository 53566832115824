import React from 'react';
import AutoSlider from './AutoSlider';

export function Creators () {
  const images = [
    ['images/creators/singer.png', 'singer'],
    ['images/creators/composer-1.png', 'composer'],
    ['images/creators/dancer.png', 'dancer'],
    ['images/creators/director.png', 'director'],
    ['images/creators/photographer-1.png', 'photographer'],
    ['images/creators/lyricist.png', 'lyricist'],
    ['images/creators/video-editor.png','video-editor'],
    ['images/creators/actor.png', 'actor'],
    ['images/creators/writer-1.png', 'writer'],
    ['images/creators/producer.png', 'producer'],
    ['images/creators/instrumentalist-1.png', 'instrumentalist'],
  ];

  return (
    <div>
      <div className="sectionHeading">
        <h1>Where Creators Connect</h1>
        <p>Fuel your creativity by working alongside passionate artists and creators from various fields, all striving to make something remarkable</p>
      </div>
      <AutoSlider images={images} interval={6000} transitionSpeed={6000} />
    </div>
  );
};


