import {ANavbar} from "./ANavbar";
import {Hero} from "./Hero";
import {Creators} from "./Creators";
import {WhyJoin} from "./WhyJoin";
import {Features} from "./Features";
import {Footer} from "./Footer";
import {CallToJoin} from "./CallToJoin";

function LandingPage() {

    return (
        <div>
            <ANavbar />
            <Hero />
            <Creators />
            <WhyJoin />
            <Features />
            <CallToJoin />
            <Footer />
        </div>
    )
}


export default LandingPage;

