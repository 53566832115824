import React, { useEffect, useRef } from 'react';

const AutoSlider = ({ images, interval = 4000, transitionSpeed = 4000 }) => {
  const sliderRef = useRef(null);
  const totalImages = images.length;
  const isMobile = window.innerWidth <= 768;
  const slideWidth = 100 / (totalImages * 4); // Each slide takes up half the width of the total slides

  if (isMobile){
    interval = 2000;
    transitionSpeed = 2000;
  }

  useEffect(() => {
    let startIndex = 0;

    const slide = () => {
      if (sliderRef.current) {
        startIndex = (startIndex + 1) % totalImages;
        sliderRef.current.style.transition = `transform ${transitionSpeed}ms linear`;
        sliderRef.current.style.transform = `translateX(-${startIndex * slideWidth * 2}%)`;
      }
    };

    const intervalId = setInterval(slide, interval);

    return () => clearInterval(intervalId);
  }, [totalImages, interval, transitionSpeed, slideWidth]);

  return (
    <div style={{ overflow: 'hidden', padding: '5rem' }}>
      <div
        ref={sliderRef}
        style={{
          display: 'flex',
          width: `${totalImages * 200}%`, // Double the width to allow sliding through all images
        }}
      >
        {images.concat(images).map((image, index) => (
          <div
            key={index}
            style={{
              width: isMobile ? `${slideWidth*2}%` : `${slideWidth}%`,
              flexShrink: 0,
              boxSizing: 'border-box',
              padding: '15px',
            }}
          >
            <div className='imageCard'>
              <img
                loading="lazy"
                src={image[0]}
                alt={`Slide ${index}`}
                style={{ 
                  width: isMobile ? '100%' : '80%', 
                  borderRadius: '30px', 
                  border: '4px solid #4f55ff' 
                }}
              />
              <div className='photoTag'>{image[1]}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AutoSlider;
