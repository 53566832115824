import React from "react";

export function Hero() {

  return (
    <div id="hero">
      <div className="Hero">

        <div className="image-container">
        <img src="images/1.png" loading="lazy" alt="Img 1" />
          <img src="images/8.png" className="AbsoluteRight" alt="Img4" />
          
          <img src="images/5.png" loading="lazy" className="AbsoluteRight" alt="Img1" />
          <img src="images/4.png" loading="lazy" alt="Img 4" />
          <img src="images/3.png" loading="lazy" className="AbsoluteRight" alt="Img3" />
          <img src="images/6.png" loading="lazy" alt="Img 2" />
          <img src="images/7.png" loading="lazy" className="AbsoluteRight" alt="Img3" />
          
          <img src="images/2.png" alt="Img 2" />

          <h1 className="line">
            Unleash Your Creativity
          </h1>
          <div className="words">
            <h1 className="word1">Connect</h1>
            <h1 className="word2">Collaborate</h1>
            <h1 className="word3">Create</h1>
          </div>
          <div className="HeroDescription">
            <h4>A networking platform for independent artists in the creative industry to connect, collaborate, and create.</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
