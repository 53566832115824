import React from 'react';

export function WhyJoin() {
  
  return (
    <div>
      <div className="sectionHeading">
        <h1>What’s in It for You?</h1>
        <p>From collaboration opportunities to professional growth, see how becoming part of our platform can elevate your artistic journey.</p>
      </div>

      <div className='joinCardContainer'>
        <div className='joinCard'>
          <div style={{ background: '#4f55ff', zIndex: '20', position: 'relative'}}>
            <h3>
              Collaborate Across Disciplines
            </h3>
            <img loading="lazy" src="/images/join/collaborate.png" alt="" />
          </div>
          <p>Connect with like-minded creatives across various fields. Whether you're looking to co-write a song, produce a film, or edit a manuscript, this is the place to find your ideal partners.</p>
        </div>

        <div className='joinCard'>
          <div style={{ background: '#4f55ff', zIndex: '20', position: 'relative'}}>
            <h3>
            Grow Your Network
            </h3>
            <img loading="lazy" src="/images/join/network.png" alt="" />
          </div>
          <p>Expand your professional network by connecting with artists, producers, and industry professionals from around the world.</p>
        </div>

        <div className='joinCard'>
          <div style={{ background: '#4f55ff', zIndex: '20', position: 'relative'}}>
            <h3>
            Access Opportunities
            </h3>
            <img loading="lazy" src="/images/join/opportunities.png" alt="" />
          </div>
          <p>Discover projects and opportunities that match your skills and passions. Turn your ideas into reality with the right team by your side.</p>
        </div>

      </div>
    </div>
  );
};



