import React from "react";
 
export function ANavbar() {
  const [openNav, setOpenNav] = React.useState(false);
 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);
 
  return (
    <div>
        <nav className="Navbar">
            <img height={50} loading="lazy" src="logo.png" alt="" />
            <ul>
                <li><a href="#">About</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>
            <ul>
                <a href="#join"><div className='smallBtn'><button>Join Now!</button></div></a>
            </ul>
        </nav>
    </div>
  );
}