import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export default function FeedbackModal({ showModal, closeModal }) {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        country: '',
        phone_number: '',
        feedback: ''
      });
    
    if (!showModal) return null;
    
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      // Add your form validation logic here
      if (formData.name.length < 3 || formData.email.length < 3 || formData.country.length < 3 || formData.country.length < 3) {
        alert('Please fill in all required fields correctly!');
        return;
      }

      fetch('/api/feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          alert('Thank you for your valuable feedback!');
        }
        else if (data.error) {
          alert(`Oops! ${data.error}`);
        }
        else {
          alert(`An error occurred. Please try again later.`);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Error! Something went wrong.');
      });

    };

    const isMobile = window.innerWidth <= 768;

    return (

        <div>
            <div className="modalOverlay">
                <div className="modalBox gifBackground" style={{ padding: isMobile ? "1rem" : "5rem" }} >
                    <button style={{ position: "absolute", top: "2rem", right: "2rem" }} onClick={closeModal} className="modalCloseButton">
                        <XMarkIcon style={{ color: 'black', height: '1.5rem', width: '1.5rem' }} />
                    </button>
                    <div className="modelTitle">
                        <h1>Feedback / Suggestion</h1>
                        <p>We are always looking to improve this platform. We will be grateful for your feedback.</p>
                    </div>
                    <div className="modalForm">
                        <form onSubmit={handleSubmit}>
                            <input type="text" placeholder="Full Name" name='name' value={formData.name} onChange={handleChange} required />
                            <input type="email" placeholder="Email" name='email' value={formData.email} onChange={handleChange} required />
                            <input type="text" placeholder='Country' name='country' value={formData.country} onChange={handleChange} required />
                            <input type="phone" placeholder='Phone Number (optional)' name='phone_number' value={formData.phone_number} onChange={handleChange} />
                            <textarea rows={10} id="" required placeholder='Description' name='feedback' value={formData.feedback} onChange={handleChange}></textarea>
                            <input type="submit" value="Submit" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
