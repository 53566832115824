import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function JoinModal({ showModal, closeModal }) {

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: 'Test',
    email: '',
    country: '',
    phone_number: '',
    platform_rating: 4,
  });
  const [interests, setInterests] = useState({
    singing: false,
    dancing: false,
    composing: false,
    writing: false,
    "video Editing": false,
    directing: false,
    producing: false,
    acting: false,
    "Playing Instrument": false,
    photography: false,
    other: false
  });

  const handleSelectChange = (event) => {
    const { name, checked } = event.target;
    setInterests((prevInterests) => ({
      ...prevInterests,
      [name]: checked,
    }));
  };

  if (!showModal) return null;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSlider = (e) => {
    setFormData({
      ...formData,
      platform_rating: e.target.value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add your form validation logic here
    if (formData.first_name.length < 3 || formData.email.length < 3 || formData.country.length < 3) {
      alert('Please fill in all required fields correctly!');
      return;
    }
    formData.interested_arts = Object.keys(interests).filter(key => interests[key]);

    console.log(formData);

    fetch('/api/join', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      if(data.success) {
        alert('Thank you for joining! We will be updating you with latest updates.');
      }
      else if (data.error) {
        alert(`Oops! ${data.error}`);
      }
      else {
        alert(`An error occurred. Please try again later.`);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Error! Something went wrong.');
    });
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div>
      <div className="modalOverlay">
        <div className="modalBox gifBackground" style={{ padding: isMobile ? "1rem" : "5rem" }} >
          <button style={{ position: "absolute", top: "2rem", right: "2rem" }} onClick={closeModal} className="modalCloseButton">
            <XMarkIcon style={{ color: 'black', height: '1.5rem', width: '1.5rem' }} />
          </button>
          <div className="modelTitle">
            <h1>Get Early Access</h1>
            <p>Be one of the first to explore our innovative features and network to enhance your creative journey.</p>
          </div>
          <div className="modalForm">
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder="Full Name" name='first_name' value={formData.name} onChange={handleChange} required />
              <input type="email" placeholder="Email" name='email' value={formData.email} onChange={handleChange} required />
              <input type="text" placeholder='Country' name='country' value={formData.country} onChange={handleChange} required />
              <input type="phone" placeholder='Phone Number' name='phone_number' value={formData.phone} onChange={handleChange} />
              <p className='normal-text'>I am interested in:</p>

              <div className='selectOptions'>
                {Object.keys(interests).map((interest) => (
                  <div key={interest} className='selectOption'>
                    <Checkbox
                      checked={interests[interest]}
                      onChange={handleSelectChange}
                      name={interest}
                      sx={{
                        '&.Mui-checked': {
                          color: '#4f55ff',
                        },
                      }}
                    />
                    <label>
                      {interest.charAt(0).toUpperCase() + interest.slice(1)}
                    </label>
                  </div>
                ))}
              </div>

              <div style={{ textAlign: "center" }}>
              <p className='normal-text'>How badly you need such platform: {formData.platform_rating} / 5</p>
                <input
                  type="range"
                  min={0}
                  max={5}
                  step={0.1}
                  value={formData.platform_rating}
                  onChange={(e) => handleSlider(e)}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>

              <input type="submit" value="Join" />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
