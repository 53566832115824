import React, {useState} from 'react';
import FeedbackModal from './FeedbackModal';

export function Footer() {
    const [showModal, setShowModal] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        country: '',
        phone_number: ''
      });
    
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      // Add your form validation logic here
      if (formData.name.length < 3 || formData.email.length < 3 || formData.country.length < 3) {
        alert('Please fill in all required fields correctly!');
        return;
      }

      fetch('/api/collab', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData),
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          alert('Thank you for your interest! You will be contacted soon.');
        }
        else if (data.error) {
          alert(`Oops! ${data.error}`);
        }
        else {
          alert(`An error occurred. Please try again later.`);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Error! Something went wrong.');
      });
    };

    const openModal = () => {
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
      };

    const isMobile = window.innerWidth <= 768;
  return (
    <div>
        <FeedbackModal showModal={showModal} closeModal={closeModal} />
        <div className="footer" style={isMobile ? {justifyContent: 'left'} : {justifyContent: 'space-around'}}>
            <div className="leftFooter" id="contact">
                <h3>I want to collab on this project!</h3>
                <div className="collabForm">
                    <form onSubmit={handleSubmit}>
                        <input type="text" placeholder="Full Name" name='name' value={formData.name} onChange={handleChange} />
                        <input type="email" placeholder="Email" name='email' value={formData.email} onChange={handleChange} />
                        <input type="text" placeholder='Country' name='country' value={formData.country} onChange={handleChange} />
                        <input type="phone" placeholder='Phone Number' name='phone_number' value={formData.phone_number} onChange={handleChange} />
                        <input type="submit" value="Submit" />
                    </form>
                </div>
            </div>

            <div className="rightFooter" style={isMobile ? {paddingLeft: '3rem',paddingTop: '2rem'} : null}>
                <ul>
                    <li><a href="#join">Join Now</a></li>
                    <li><a href="#hero">About</a></li>
                    <li><div className='smallBtn'><button onClick={openModal}>Feedback / Suggestion</button></div></li>
                </ul>
                <h3>Contact:</h3>
                <p>Place: Hyderabad, Telangana, India</p>
                <p>Email: connect@devs2go.com</p>
            </div>
        </div>

        <p className="copyRight">Copyright &copy; 2024. All rights reserved.</p>
    </div>
  );
};




