import React from 'react';

export function Features() {
  return (
    <div>
      <div className="sectionHeading">
        <h1>What We Offer</h1>
        <p>From seamless collaboration tools to comprehensive project management, our platform provides everything you need to bring your artistic visions to life.</p>
      </div>

      <div className='featureCardContainer'>
        <div className="featureCard">
          <div className='featureCardImage'>
            <img loading="lazy" src="/images/features/user-friendly-interface.png" alt="" />
          </div>
          <h3>
            User-Friendly Interface
          </h3>
          <p>Navigate with ease, whether you're searching for talent or managing your own projects.</p>
        </div>

        <div className="featureCard">
          <div className='featureCardImage'>
            <img loading="lazy" src="/images/features/advanced-search.png" alt="" />
          </div>
          <h3>
            Advanced Search Filters
          </h3>
          <p>Find the perfect match for your project by filtering through specific skills, genres, and experience levels.</p>
        </div>

        <div className="featureCard">
          <div className='featureCardImage'>
            <img loading="lazy" src="/images/features/project-portfolio-management.png" alt="" />
          </div>
          <h3>
            Projects Portfolio Management
          </h3>
          <p>Effortlessly organize, track, and manage all your creative projects in one place.</p>
        </div>
      </div>
    </div>
  );
};




