import React, {useState} from 'react';
import JoinModal from './JoinModal';

export function CallToJoin() {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
      };
    
      const closeModal = () => {
        setShowModal(false);
      };

    return (
        <div id='join'>
            <JoinModal showModal={showModal} closeModal={closeModal} />
            <div className="sectionHeading">
                <h1>Get Ready to Elevate Your Creativity</h1>
                <p>We’re on the brink of launching a groundbreaking platform that will transform the way creators connect, collaborate, and manage their projects. Whether you’re a writer, producer, editor, or any type of creative, this is your opportunity to be part of something extraordinary from the start.</p>
            </div>

            <div className="Container2">
                <div className="box1">
                    <img loading="lazy" src="/images/join/join.png" alt="Join" />
                </div>
                <div className="box2">
                    <h3>Why Wait?</h3>

                    <ul>
                        <li>
                            <b>Exclusive Early Access</b> Be one of the first to explore our innovative features and network to enhance your creative journey.
                        </li>
                        <li>
                            <b>Influence the Future</b> Your feedback will help shape the platform, ensuring it meets the needs of creators just like you.
                        </li>
                        <li>
                            <b>Special Benefits</b> Early adopters will receive exclusive perks, including access to premium features, priority support, and more.
                        </li>
                    </ul>

                    <div className='myButton'>
                        <button
                            onClick={openModal}
                        >Join Now!</button>
                    </div>

                </div>
            </div>
        </div>
    );
};




